import React, {Component} from 'react';
import styles from "../GameTable.module.css";
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withGameTableContext} from "../../../contexts/GameTableContext"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import Volume from "../../../components/icons/volume.svg";
import VolumeBar from '../../../components/ui/VolumeBar/VolumeBar';

class WonBoardToolbar extends Component {
    refVolumeBox = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            top5Menu: false,
            volumeBar: false
        }
    }
    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    onChangeNeighbourBet = (neighbourBet) => {
        const {gameTableContext} = this.props;
        gameTableContext.onUpdateState({
            neighbourBet
        })
    }
    handleOpenVolumeBar = (e) => {
        this.setState({
            volumeBar: !this.state.volumeBar
        })
    }
    handleClickOutside = (event) => {
        if (this.refVolumeBox && !this.refVolumeBox.contains(event.target)) {
            this.setState({
                volumeBar: false
            })
        }
    }
    render() {
        const {applicationContext, gameTableContext} = this.props;
        return (
            <div className={styles.WonBoardToolbar}>
                <button className={classNames(styles.WonBoardButton, this.state.volumeBar && styles.WonBoardButtonActive)}>
                    <div className={styles.WonBoardButtonCircle} ref={(ref) => this.refVolumeBox = ref}>
                        {this.state.volumeBar && <div className={styles.VolumeBox}>
                            <div>
                                <VolumeBar label="STUDIO" defaultValue={gameTableContext.state.volume} onChangeVolume={(value) => gameTableContext.handleChangeVolume(value)} />
                                <VolumeBar label="VOICE OVER" defaultValue={gameTableContext.state.voiceVolume} onChangeVolume={(value) => gameTableContext.handleChangeVoiceVolume(value)}/>
                            </div>
                        </div>}
                        <img src={Volume} width={"60%"} onClick={this.handleOpenVolumeBar}/>
                    </div>
                </button>
                <button className={classNames(styles.WonBoardButton, gameTableContext.state.table === "stats" && styles.WonBoardButtonActive)} onClick={(e) => gameTableContext.handleChangeTable("stats")}>
                    <div className={styles.WonBoardButtonCircle}>
                        <FontAwesomeIcon icon={"chart-bar"} />
                    </div>

                </button>
                <button className={classNames(styles.WonBoardButton, gameTableContext.state.table === "won" && styles.WonBoardButtonActive)} onClick={(e) => gameTableContext.handleChangeTable("won")}>
                    <div className={styles.WonBoardButtonCircle}>
                        <FontAwesomeIcon icon={"calculator"} />
                    </div>
                </button>
                <button className={classNames(styles.WonBoardButton, styles.WonBoardLastButton, gameTableContext.state.table === "top5" && styles.WonBoardButtonActive)}
                        onClick={(e) => gameTableContext.handleChangeTable("top5")}>
                    <div className={styles.WonBoardButtonCircle}>
                        <span>TOP<br/>5</span>
                    </div>
                </button>

                <div className={styles.NeighboursBar}>
                    <label>{applicationContext.translator("Neighbours")}</label>
                    <button className={classNames(styles.NeighbourButton, gameTableContext.state.neighbourBet === 0 && styles.NeighbourButtonActive)} onClick={(e) => this.onChangeNeighbourBet(0)}>
                        0
                    </button>
                    <button className={classNames(styles.NeighbourButton, gameTableContext.state.neighbourBet === 1 && styles.NeighbourButtonActive)} onClick={(e) => this.onChangeNeighbourBet(1)}>
                        1
                    </button>
                    <button className={classNames(styles.NeighbourButton, gameTableContext.state.neighbourBet === 2 && styles.NeighbourButtonActive)} onClick={(e) => this.onChangeNeighbourBet(2)}>
                        2
                    </button>
                    <button className={classNames(styles.NeighbourButton, gameTableContext.state.neighbourBet === 3 && styles.NeighbourButtonActive)} onClick={(e) => this.onChangeNeighbourBet(3)}>
                        3
                    </button>
                </div>
            </div>
        );
    }
}

export default withApplicationContext(withGameTableContext(WonBoardToolbar));