import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import { withGameTableContext } from '../../../contexts/GameTableContext';
import EN_NUMBER_0 from "../../../assets/voice/en/0.mp3";
import EN_NUMBER_1 from "../../../assets/voice/en/1.mp3";
import EN_NUMBER_2 from "../../../assets/voice/en/2.mp3";
import EN_NUMBER_3 from "../../../assets/voice/en/3.mp3";
import EN_NUMBER_4 from "../../../assets/voice/en/4.mp3";
import EN_NUMBER_5 from "../../../assets/voice/en/5.mp3";
import EN_NUMBER_6 from "../../../assets/voice/en/6.mp3";
import EN_NUMBER_7 from "../../../assets/voice/en/7.mp3";
import EN_NUMBER_8 from "../../../assets/voice/en/8.mp3";
import EN_NUMBER_9 from "../../../assets/voice/en/9.mp3";
import EN_NUMBER_10 from "../../../assets/voice/en/10.mp3";
import EN_NUMBER_11 from "../../../assets/voice/en/11.mp3";
import EN_NUMBER_12 from "../../../assets/voice/en/12.mp3";
import EN_NUMBER_13 from "../../../assets/voice/en/13.mp3";
import EN_NUMBER_14 from "../../../assets/voice/en/14.mp3";
import EN_NUMBER_15 from "../../../assets/voice/en/15.mp3";
import EN_NUMBER_16 from "../../../assets/voice/en/16.mp3";
import EN_NUMBER_17 from "../../../assets/voice/en/17.mp3";
import EN_NUMBER_18 from "../../../assets/voice/en/18.mp3";
import EN_NUMBER_19 from "../../../assets/voice/en/19.mp3";
import EN_NUMBER_20 from "../../../assets/voice/en/20.mp3";
import EN_NUMBER_21 from "../../../assets/voice/en/21.mp3";
import EN_NUMBER_22 from "../../../assets/voice/en/22.mp3";
import EN_NUMBER_23 from "../../../assets/voice/en/23.mp3";
import EN_NUMBER_24 from "../../../assets/voice/en/24.mp3";
import EN_NUMBER_25 from "../../../assets/voice/en/25.mp3";
import EN_NUMBER_26 from "../../../assets/voice/en/26.mp3";
import EN_NUMBER_27 from "../../../assets/voice/en/27.mp3";
import EN_NUMBER_28 from "../../../assets/voice/en/28.mp3";
import EN_NUMBER_29 from "../../../assets/voice/en/29.mp3";
import EN_NUMBER_30 from "../../../assets/voice/en/30.mp3";
import EN_NUMBER_31 from "../../../assets/voice/en/31.mp3";
import EN_NUMBER_32 from "../../../assets/voice/en/32.mp3";
import EN_NUMBER_33 from "../../../assets/voice/en/33.mp3";
import EN_NUMBER_34 from "../../../assets/voice/en/34.mp3";
import EN_NUMBER_35 from "../../../assets/voice/en/35.mp3";
import EN_NUMBER_36 from "../../../assets/voice/en/36.mp3";
import FA_NUMBER_0 from "../../../assets/voice/fa/0.mp3";
import FA_NUMBER_1 from "../../../assets/voice/fa/1.mp3";
import FA_NUMBER_2 from "../../../assets/voice/fa/2.mp3";
import FA_NUMBER_3 from "../../../assets/voice/fa/3.mp3";
import FA_NUMBER_4 from "../../../assets/voice/fa/4.mp3";
import FA_NUMBER_5 from "../../../assets/voice/fa/5.mp3";
import FA_NUMBER_6 from "../../../assets/voice/fa/6.mp3";
import FA_NUMBER_7 from "../../../assets/voice/fa/7.mp3";
import FA_NUMBER_8 from "../../../assets/voice/fa/8.mp3";
import FA_NUMBER_9 from "../../../assets/voice/fa/9.mp3";
import FA_NUMBER_10 from "../../../assets/voice/fa/10.mp3";
import FA_NUMBER_11 from "../../../assets/voice/fa/11.mp3";
import FA_NUMBER_12 from "../../../assets/voice/fa/12.mp3";
import FA_NUMBER_13 from "../../../assets/voice/fa/13.mp3";
import FA_NUMBER_14 from "../../../assets/voice/fa/14.mp3";
import FA_NUMBER_15 from "../../../assets/voice/fa/15.mp3";
import FA_NUMBER_16 from "../../../assets/voice/fa/16.mp3";
import FA_NUMBER_17 from "../../../assets/voice/fa/17.mp3";
import FA_NUMBER_18 from "../../../assets/voice/fa/18.mp3";
import FA_NUMBER_19 from "../../../assets/voice/fa/19.mp3";
import FA_NUMBER_20 from "../../../assets/voice/fa/20.mp3";
import FA_NUMBER_21 from "../../../assets/voice/fa/21.mp3";
import FA_NUMBER_22 from "../../../assets/voice/fa/22.mp3";
import FA_NUMBER_23 from "../../../assets/voice/fa/23.mp3";
import FA_NUMBER_24 from "../../../assets/voice/fa/24.mp3";
import FA_NUMBER_25 from "../../../assets/voice/fa/25.mp3";
import FA_NUMBER_26 from "../../../assets/voice/fa/26.mp3";
import FA_NUMBER_27 from "../../../assets/voice/fa/27.mp3";
import FA_NUMBER_28 from "../../../assets/voice/fa/28.mp3";
import FA_NUMBER_29 from "../../../assets/voice/fa/29.mp3";
import FA_NUMBER_30 from "../../../assets/voice/fa/30.mp3";
import FA_NUMBER_31 from "../../../assets/voice/fa/31.mp3";
import FA_NUMBER_32 from "../../../assets/voice/fa/32.mp3";
import FA_NUMBER_33 from "../../../assets/voice/fa/33.mp3";
import FA_NUMBER_34 from "../../../assets/voice/fa/34.mp3";
import FA_NUMBER_35 from "../../../assets/voice/fa/35.mp3";
import FA_NUMBER_36 from "../../../assets/voice/fa/36.mp3";
import EN_BLACK from "../../../assets/voice/en/Black.mp3";
import EN_RED from "../../../assets/voice/en/Red.mp3";
import EN_C50OR100 from "../../../assets/voice/en/C50OR100.mp3";
import EN_CHARGE_CREDIT from "../../../assets/voice/en/chargeCredit.mp3";
import EN_CHIPS_LIMITED from "../../../assets/voice/en/chipsLimited.mp3";
import EN_CHIPS_MAX_BET_50 from "../../../assets/voice/en/maximum_bet_50$.mp3";
import EN_CHIPS_MAX_BET_100 from "../../../assets/voice/en/maximum_bet_100$.mp3";
import EN_CHIPS_MAX_BET_500 from "../../../assets/voice/en/maximum_bet_500$.mp3";
import EN_NO_BETS from "../../../assets/voice/en/noBets.mp3";
import EN_OUTSIDE_BETS from "../../../assets/voice/en/outside_bets.mp3";
import EN_PLACE_BETS from "../../../assets/voice/en/placeBets.mp3";
import EN_WAIT_NEXT_ROUND from "../../../assets/voice/en/waitNextRound.mp3";
import EN_YOU_WIN from "../../../assets/voice/en/youWin.mp3";
import FA_BLACK from "../../../assets/voice/fa/Black.mp3";
import FA_RED from "../../../assets/voice/fa/Red.mp3";
import FA_C50OR100 from "../../../assets/voice/fa/C50OR100.mp3";
import FA_CHARGE_CREDIT from "../../../assets/voice/fa/chargeCredit.mp3";
import FA_CHIPS_LIMITED from "../../../assets/voice/fa/chipsLimited.mp3";
import FA_CHIPS_MAX_BET_50 from "../../../assets/voice/fa/maximum_bet_50$.mp3";
import FA_CHIPS_MAX_BET_100 from "../../../assets/voice/fa/maximum_bet_100$.mp3";
import FA_CHIPS_MAX_BET_500 from "../../../assets/voice/fa/maximum_bet_500$.mp3";
import FA_NO_BETS from "../../../assets/voice/fa/noBets.mp3";
import FA_OUTSIDE_BETS from "../../../assets/voice/fa/outside_bets.mp3";
import FA_PLACE_BETS from "../../../assets/voice/fa/placeBets.mp3";
import FA_WAIT_NEXT_ROUND from "../../../assets/voice/fa/waitNextRound.mp3";
import FA_YOU_WIN from "../../../assets/voice/fa/youWin.mp3";
import FA_SERVER_RECEIVED from "../../../assets/voice/fa/serverReceived.mp3";
import SOUND_BALL from "../../../assets/voice/en/ball.mp3";
import SOUND_BEEP from "../../../assets/voice/en/beep.mp3";
import SOUND_CHIP from "../../../assets/voice/en/chip.mp3";
import SOUND_COUNTER_NUMBER from "../../../assets/voice/en/Counter_Number.mp3";
import SOUND_COUNTER_COMPLETE from "../../../assets/voice/en/Counter_Completed.mp3";
import SOUND_WON from "../../../assets/voice/en/won.mp3";
const voices = {
    "en":{
        "0": EN_NUMBER_0,
        "1": EN_NUMBER_1,
        "2": EN_NUMBER_2,
        "3": EN_NUMBER_3,
        "4": EN_NUMBER_4,
        "5": EN_NUMBER_5,
        "6": EN_NUMBER_6,
        "7": EN_NUMBER_7,
        "8": EN_NUMBER_8,
        "9": EN_NUMBER_9,
        "10": EN_NUMBER_10,
        "11": EN_NUMBER_11,
        "12": EN_NUMBER_12,
        "13": EN_NUMBER_13,
        "14": EN_NUMBER_14,
        "15": EN_NUMBER_15,
        "16": EN_NUMBER_16,
        "17": EN_NUMBER_17,
        "18": EN_NUMBER_18,
        "19": EN_NUMBER_19,
        "20": EN_NUMBER_20,
        "21": EN_NUMBER_21,
        "22": EN_NUMBER_22,
        "23": EN_NUMBER_23,
        "24": EN_NUMBER_24,
        "25": EN_NUMBER_25,
        "26": EN_NUMBER_26,
        "27": EN_NUMBER_27,
        "28": EN_NUMBER_28,
        "29": EN_NUMBER_29,
        "30": EN_NUMBER_30,
        "31": EN_NUMBER_31,
        "32": EN_NUMBER_32,
        "33": EN_NUMBER_33,
        "34": EN_NUMBER_34,
        "35": EN_NUMBER_35,
        "36": EN_NUMBER_36,
        "Black": EN_BLACK,
        "Red": EN_RED,
        "C50OR100": EN_C50OR100,
        "chargeCredit": EN_CHARGE_CREDIT,
        "chipsLimited": EN_CHIPS_LIMITED,
        "maximum_bet_50$": EN_CHIPS_MAX_BET_50,
        "maximum_bet_100$": EN_CHIPS_MAX_BET_100,
        "maximum_bet_500$": EN_CHIPS_MAX_BET_500,
        "noBets": EN_NO_BETS,
        "outside_bets": EN_OUTSIDE_BETS,
        "placeBets": EN_PLACE_BETS,
        "waitNextRound": EN_WAIT_NEXT_ROUND,
        "youWin": EN_YOU_WIN,
        "ball": SOUND_BALL,
        "beep": SOUND_BEEP,
        "chip": SOUND_CHIP,
        "Counter_Number": SOUND_COUNTER_NUMBER,
        "Counter_Completed": SOUND_COUNTER_COMPLETE,
        "won": SOUND_WON
    },
    "fa":{
        "0": FA_NUMBER_0,
        "1": FA_NUMBER_1,
        "2": FA_NUMBER_2,
        "3": FA_NUMBER_3,
        "4": FA_NUMBER_4,
        "5": FA_NUMBER_5,
        "6": FA_NUMBER_6,
        "7": FA_NUMBER_7,
        "8": FA_NUMBER_8,
        "9": FA_NUMBER_9,
        "10": FA_NUMBER_10,
        "11": FA_NUMBER_11,
        "12": FA_NUMBER_12,
        "13": FA_NUMBER_13,
        "14": FA_NUMBER_14,
        "15": FA_NUMBER_15,
        "16": FA_NUMBER_16,
        "17": FA_NUMBER_17,
        "18": FA_NUMBER_18,
        "19": FA_NUMBER_19,
        "20": FA_NUMBER_20,
        "21": FA_NUMBER_21,
        "22": FA_NUMBER_22,
        "23": FA_NUMBER_23,
        "24": FA_NUMBER_24,
        "25": FA_NUMBER_25,
        "26": FA_NUMBER_26,
        "27": FA_NUMBER_27,
        "28": FA_NUMBER_28,
        "29": FA_NUMBER_29,
        "30": FA_NUMBER_30,
        "31": FA_NUMBER_31,
        "32": FA_NUMBER_32,
        "33": FA_NUMBER_33,
        "34": FA_NUMBER_34,
        "35": FA_NUMBER_35,
        "36": FA_NUMBER_36,
        "Black": FA_BLACK,
        "Red": FA_RED,
        "C50OR100": FA_C50OR100,
        "chargeCredit": FA_CHARGE_CREDIT,
        "chipsLimited": FA_CHIPS_LIMITED,
        "maximum_bet_50$": FA_CHIPS_MAX_BET_50,
        "maximum_bet_100$": FA_CHIPS_MAX_BET_100,
        "maximum_bet_500$": FA_CHIPS_MAX_BET_500,
        "noBets": FA_NO_BETS,
        "outside_bets": FA_OUTSIDE_BETS,
        "placeBets": FA_PLACE_BETS,
        "waitNextRound": FA_WAIT_NEXT_ROUND,
        "youWin": FA_YOU_WIN,
        "ball": SOUND_BALL,
        "beep": SOUND_BEEP,
        "chip": SOUND_CHIP,
        "Counter_Number": SOUND_COUNTER_NUMBER,
        "Counter_Completed": SOUND_COUNTER_COMPLETE,
        "serverReceived": FA_SERVER_RECEIVED
    }
}
class SoundPlayer extends Component {
    audio = new Audio();
    queue = [];
    constructor(props) {
        super(props);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const {gameTableContext} = this.props;
        if(gameTableContext.state.voiceVolume !== prevProps.gameTableContext.state.voiceVolume && this.audio){
            this.audio.volume = gameTableContext.state.voiceVolume;
        }
    }
    stop = () => {
        this.audio.pause();
    }
    play = (name, force = false, loop = false, complete = null) => {
        const {applicationContext, gameTableContext} = this.props;
        const {lang} = applicationContext.state;
        const {voiceVolume} = gameTableContext.state;
        const self = this;
        if(!voices.hasOwnProperty(lang)) return;
        if(!voices[lang].hasOwnProperty(name)){
            console.error("Notfound","force:" + force, "loop:" + loop , "volume: "+voiceVolume, name);
            return;
        }
        const voice = voices[lang][name];
        
        if(force && !this.audio.paused){
            setTimeout(() => {
                self.play(name, loop, force, complete);
            }, 500)
        }else{
            if(!this.audio.paused) {
                console.log("Already playing...","force:" + force, "loop:" + loop , "volume: "+voiceVolume, name)
                return;
            }
            this.audio.src = voice;
            console.log("Play","force:" + force, "loop:" + loop , "volume: "+voiceVolume, name)
            this.audio.volume = voiceVolume;
            this.audio.loop = loop;
            this.audio.onpause = function(){
                console.log("Paused","force:" + force, "loop:" + loop , "volume: "+voiceVolume, name);
                if(complete) complete();
            }
            this.audio.play();
        }
        
    }
    playGroup = (sounds) => {
        if(sounds === null || sounds.length === 0) {
            this.audio.pause();
            return;
        }
        const {applicationContext} = this.props;
        const self = this;
        if(!this.audio.paused){
            setTimeout(() => {
                self.playGroup(sounds);
            }, 500)
        }else{
            this.play(sounds[0], true, false, () => {
                sounds.splice(0, 1);
                self.playGroup(sounds);
            })
        }
        
        
    }

    render() {
        const {applicationContext} = this.props;
        return (
            <React.Fragment>
                
            </React.Fragment>
        );
    }
}

export default withApplicationContext(withGameTableContext(SoundPlayer));