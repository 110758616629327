import React, {Component} from 'react';
import styles from "../GameTable.module.css";
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withGameTableContext} from "../../../contexts/GameTableContext"
import classNames from "classnames";
import {
    accountCodeParse,
    convertArrayToMap,
    convertMapToObject,
    filterFormula,
    numberWithCommas,
    parseFavorites,
    sumChips
} from "../../../helpers/helpers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Popup from "../../../components/ui/Popup";
import * as Yup from "yup";
import {Formik} from "formik";
import {Button, Col, Form, Row} from "react-bootstrap";
import UserService from "../../../services/user.service";
import {HOME_WEBSITE, VOLUME_LEVEL_1, VOLUME_LEVEL_2, VOLUME_LEVEL_3} from "../../../helpers/constants";
import ClearIcon from "../../../components/icons/ClearIcon.png";
import ClearIconDisabled from "../../../components/icons/ClearIconDisabled.png";
import VolumeLevel3 from "../../../components/icons/Volume03.png";
import VolumeLevel2 from "../../../components/icons/Volume02.png";
import VolumeLevel1 from "../../../components/icons/Volume01.png";
import VolumeMute from "../../../components/icons/VolumeMute.png";
import WheelIcon from "../../../components/icons/WheelIcon.png";

import {NavLink} from "react-router-dom";
import {
    ROUTE_LOGIN,
    ROUTE_PROFILE_DEPOSIT,
    ROUTE_PROFILE_DEPOSIT_HISTORY, ROUTE_PROFILE_PLAYED_HISTORY,
    ROUTE_PROFILE_WITHDRAWAL,
    ROUTE_PROFILE_WITHDRAWAL_HISTORY,
    ROUTE_TABLE_LIVE_PLAY,
    ROUTE_TABLE_PRACTICE_PLAY,
    ROUTE_USER_PROFILE
} from "../../../routers/routes";

class MobileTools extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dotsOpened: false,
            showVideoPopup: false,
            balance: props.gameTableContext.state.balance
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.gameTableContext.state.balance !== this.props.gameTableContext.state.balance){
            this.setState({
                balance: this.props.gameTableContext.state.balance
            })
        }
    }

    handleChangeVolume = (e) => {
        const {gameTableContext} = this.props;
        let {volume} = gameTableContext.state;
        if(volume === VOLUME_LEVEL_3) volume = 0;
        else if(volume === 0) volume = VOLUME_LEVEL_1;
        else if(volume === VOLUME_LEVEL_1) volume = VOLUME_LEVEL_2;
        else if(volume === VOLUME_LEVEL_2) volume = VOLUME_LEVEL_3;
        gameTableContext.handleChangeVolume(volume);
    }
    handleVideoPopup = (e) => {
        const {gameTableContext} = this.props;
        if(gameTableContext.state.practice){
            this.setState({
                showVideoPopup: true
            })
        }else{
            if(gameTableContext.state.waitNextRound)
                return;
            gameTableContext.state.windows.video?
                gameTableContext.handleCloseWindow("video"):
                gameTableContext.handleOpenWindow("video")
        }
    }
    render() {
        const {gameTableContext, applicationContext} = this.props;
        return (
            <React.Fragment>
                <div className={styles.MobileTools}>
                    <button className={classNames(styles.MobileButtonAppBar, gameTableContext.state.waitNextRound && styles.MenuDisabled)} onClick={this.handleVideoPopup}>
                        <FontAwesomeIcon icon={"video"} />
                    </button>
                    <button className={classNames(styles.MobileButtonAppBar, gameTableContext.state.waitNextRound && styles.MenuDisabled)} onClick={gameTableContext.handleClear}>
                        <img src={gameTableContext.state.waitNextRound?ClearIconDisabled:ClearIcon} width={24}/>
                    </button>
                    <button className={classNames(styles.MobileButtonAppBar, gameTableContext.state.waitNextRound && styles.MenuDisabled)} disabled={gameTableContext.state.processing} onClick={gameTableContext.handleUndo}>
                        <FontAwesomeIcon icon={"undo"} />
                    </button>
                    <button className={classNames(styles.MobileButtonAppBar, gameTableContext.state.windows.wheel && styles.MobileButtonAppBarActive)}>
                        <div className={classNames(styles.WheelButton)}
                             onClick={(e) => {
                                 gameTableContext.handleCloseWindow("account", () => {
                                     gameTableContext.state.windows.wheel?
                                         gameTableContext.handleCloseWindow("wheel"):
                                         gameTableContext.handleOpenWindow("wheel")
                                 });

                             }}
                        >
                            <img src={WheelIcon} width={42}/>
                        </div>
                    </button>
                    <button className={classNames(styles.MobileButtonAppBar, gameTableContext.state.autoRepeat && styles.MobileFloatingMenuWrapperActive, gameTableContext.state.waitNextRound && styles.MenuDisabled)} onClick={gameTableContext.handleRepeat}>
                        <FontAwesomeIcon icon={"sync-alt"} />
                    </button>
                    <button className={classNames(styles.MobileButtonAppBar, gameTableContext.state.windows.topCells && styles.MobileButtonAppBarActive, gameTableContext.state.waitNextRound && styles.MenuDisabled)}
                            onClick={(e) => {
                                gameTableContext.handleCloseWindow("account", () => {
                                    gameTableContext.state.windows.topCells?
                                        gameTableContext.handleCloseWindow("topCells"):
                                        gameTableContext.handleOpenWindow("topCells")
                                });

                            }}>
                        <FontAwesomeIcon icon={"hashtag"} />
                    </button>
                    <button className={classNames(styles.MobileButtonAppBar, (gameTableContext.state.windows.otherButtons || gameTableContext.state.windows.account) && styles.MobileButtonAppBarActive, gameTableContext.state.waitNextRound && styles.MenuDisabled)}
                            onClick={(e) => {
                                if(gameTableContext.state.windows.account){
                                    gameTableContext.handleCloseWindow("account", () => {
                                        gameTableContext.handleOpenWindow("otherButtons")
                                    });

                                }else{
                                    if(gameTableContext.state.waitNextRound)
                                        return;
                                    gameTableContext.state.windows.otherButtons?
                                        gameTableContext.handleCloseWindow("otherButtons"):
                                        gameTableContext.handleOpenWindow("otherButtons")
                                }

                            }}>
                        {!gameTableContext.state.windows.otherButtons && !gameTableContext.state.windows.account?<FontAwesomeIcon icon={"bars"} />:<FontAwesomeIcon icon={"times"} />}
                    </button>

                </div>
                <div className={classNames(styles.MobileFloatingMenu, gameTableContext.state.windows.otherButtons && styles.OpenMobileFloatingMenu)}>
                    <div className={styles.MobileFloatingMenuWrapper}>
                        <button className={classNames(styles.MobileVolumeButton)} onClick={this.handleChangeVolume}>
                            {gameTableContext.state.volume === VOLUME_LEVEL_3 && <img src={VolumeLevel3} width={20}/>}
                            {gameTableContext.state.volume === VOLUME_LEVEL_2 && <img src={VolumeLevel2} width={20}/>}
                            {gameTableContext.state.volume === VOLUME_LEVEL_1 && <img src={VolumeLevel1} width={20}/>}
                            {gameTableContext.state.volume === 0 && <img src={VolumeMute} width={20}/>}
                        </button>
                        <a href={HOME_WEBSITE} className={styles.MobileFloatingMenuLogo}><img src={`${process.env.PUBLIC_URL}/assets/img/my-roulette-light-logo.png`} width={130}/></a>
                        {process.env.REACT_APP_NAME !== "swissco.gaming" && <div className={styles.LanguagesRightOfMonitorMobile}>
                            <NavLink to={"#"} onClick={(e) => gameTableContext.doChangeLanguage("en", true)} className={gameTableContext.state.locale === "en" ? styles.LanguagesRightOfMonitorActive: ""}>English</NavLink>
                            <NavLink to={"#"} onClick={(e) => gameTableContext.doChangeLanguage("fa", true)} className={gameTableContext.state.locale === "fa" ? styles.LanguagesRightOfMonitorActive: ""}>فارسی</NavLink>
                        </div>}
                        <ul className={applicationContext.translator("direction") === "rtl" && "rtl-section"}>
                            {gameTableContext.state.userInfo && (
                                <React.Fragment>
                                    <li className={"mb-2 mt-4"}>
                                        <div><span>{applicationContext.translator("Balance")}: </span><span className={"yellow-text " + (applicationContext.translator("direction") === "rtl"?"mr-1":"ml-1")}>{numberWithCommas(this.state.balance, true)}</span></div>
                                    </li>
                                    <li className={"mb-5 mt-2"}>
                                        <div><span>{applicationContext.translator("Account ID")}:</span><span className={"yellow-text " + (applicationContext.translator("direction") === "rtl"?"mr-2":"ml-1")} style={{direction: "ltr !important"}}>{accountCodeParse(gameTableContext.state.userInfo.account_code, applicationContext.translator("direction") === "rtl")}</span></div>
                                    </li>
                                    <li>
                                        <NavLink to={"/"} onClick={(e) => {
                                            e.preventDefault();
                                            gameTableContext.handleOpenWindow("account", ROUTE_PROFILE_DEPOSIT);
                                        }}>
                                            {applicationContext.translator("Deposit")}
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={"/"} onClick={(e) => {
                                            e.preventDefault();
                                            gameTableContext.handleOpenWindow("account", ROUTE_PROFILE_WITHDRAWAL);
                                        }}>
                                            {applicationContext.translator("Withdrawal")}
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={"/"} onClick={(e) => {
                                            e.preventDefault();
                                            gameTableContext.handleOpenWindow("account", ROUTE_PROFILE_DEPOSIT_HISTORY);
                                        }}>
                                            {applicationContext.translator("Deposit History")}
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={"/"} onClick={(e) => {
                                            e.preventDefault();
                                            gameTableContext.handleOpenWindow("account", ROUTE_PROFILE_WITHDRAWAL_HISTORY);
                                        }}>
                                            {applicationContext.translator("Withdrawal History")}
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={"/"} onClick={(e) => {
                                            e.preventDefault();
                                            gameTableContext.handleOpenWindow("account", ROUTE_PROFILE_PLAYED_HISTORY);
                                        }}>
                                            {applicationContext.translator("Played History")}
                                        </NavLink>
                                    </li>
                                    <li className={"mb-5"}><NavLink to={"/"} onClick={applicationContext.onLogout}>{applicationContext.translator("Logout")}</NavLink></li>
                                </React.Fragment>
                            )}
                            {!gameTableContext.state.practice && <li><NavLink to={ROUTE_TABLE_PRACTICE_PLAY}>{applicationContext.translator("Play On Practice Table")}</NavLink></li>}
                            {gameTableContext.state.practice && <li><NavLink to={ROUTE_TABLE_LIVE_PLAY}>{applicationContext.translator("Play On Real Table")}</NavLink></li>}
                            <li><NavLink to={"/"} onClick={(e) => {
                                e.preventDefault();
                                gameTableContext.handleOpenWindow("top5")
                            }}>{applicationContext.translator("Top5")}</NavLink></li>
                            <li><NavLink to={"/"} onClick={(e) => {
                                e.preventDefault();
                                gameTableContext.handleOpenWindow("stats")
                            }}>{applicationContext.translator("Wheel Stats")}</NavLink></li>
                            <li><NavLink to={"/"} onClick={gameTableContext.handleAutoRepeat} className={gameTableContext.state.autoRepeat?styles.MobileFloatingMenuWrapperActive:""}>{gameTableContext.state.autoRepeat?applicationContext.translator("Repeat Off"):applicationContext.translator("Repeat On")}</NavLink></li>
                            {!gameTableContext.state.userInfo && (
                                <li><NavLink to={ROUTE_LOGIN}>{applicationContext.translator("Login")}</NavLink></li>
                            )}
                        </ul>
                    </div>
                </div>
                {this.state.showVideoPopup &&
                <Popup
                    show={true}
                    onClose={(e) => this.setState({ showVideoPopup: false })}
                    CancelCaption={"OK"}
                    title={applicationContext.translator("Live stream is not available")}
                    description={applicationContext.translator("Live stream is not available on practice table")}
                />
                }
            </React.Fragment>
        );
    }
}

export default withApplicationContext(withGameTableContext(MobileTools));

