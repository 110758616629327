import api from "./api";
import TokenService from "./token.service";
import {queryStringSerialize} from "../helpers/helpers";

const getUserBoard = () => {
    return api.get("/api/account/profile").then((response) => {
        TokenService.setUser(response.data);
        return TokenService.getUser();
    });
};
const getConfigData = () => {
    return api.get("/api/common/all").then((response) => {
        return response.data;
    });
};
const getCommonData = () => {
    return api.get("/api/account/common").then((response) => {
        return response.data;
    });
};
const updateUserProfile = (data) => {
    return api
        .post("/api/account/update", data)
        .then((response) => {
            return response.data;
        });
};
const doChangeEmail = (data) => {
    return api
        .post("/api/account/user/change_email", data)
        .then((response) => {
            return response.data;
        });
};
const doChangePassword = (data) => {
    return api
        .post("/api/account/user/change_password", data)
        .then((response) => {
            return response.data;
        });
};
const play = (data) => {
    return api.post("/api/account/user/play", data);
};

const action = (data) => {
    return api
        .post("/api/account/user/action", data)
        .then((response) => {
            return response.data;
        });
};

const deleteBySelf = () => {
    return api
        .post("/api/account/user/delete_self_account", {})
        .then((response) => {
            return response.data;
        });
};
const getPlayedGame = (data) => {
    return api
        .get("/api/account/user/played_game?"+ queryStringSerialize(data))
        .then((response) => {
            return response.data;
        });
};
const getPlayedHistory = (data) => {
    return api
        .get("/api/account/user/played_history?"+ queryStringSerialize(data))
        .then((response) => {
            return response.data;
        });
};
const getLogonHistory = (data) => {
    return api
        .get("/api/account/user/logon_history?"+ queryStringSerialize(data))
        .then((response) => {
            return response.data;
        });
};
const getStats = () => {
    return api
        .get("/api/account/user/stats")
        .then((response) => {
            return response.data;
        });
};

const doUpdatePassword = (url, data) => {
    return api
        .post(url, data)
        .then((response) => {
            return response.data;
        });
};
const doUpdateEmail = (url, data) => {
    return api
        .post(url, data)
        .then((response) => {
            return response.data;
        });
};
const doUpdateFavorites = (data) => {
    return api
        .post("/api/account/user/update_favorites", data)
        .then((response) => {
            return response.data;
        });
};
const doUpdateSettings = (data) => {
    return api
        .post("/api/account/user/update_settings", data)
        .then((response) => {
            return response.data;
        });
};
const doUpdateLocale = (data) => {
    return api
        .post("/api/account/user/update_locale", data)
        .then((response) => {
            return response.data;
        });
};
const getUserInfoByToken = (token) => {
    let config = {
        headers: {
            Authorization: 'Bearer ' + token
        }
    };
    return api.get("/api/account/profile", config).then((response) => {
        return response.data;
    });
};
const doCloseAnotherSessions = (device_id) => {
    return api
        .get("/api/account/user/close_another_sessions?device_id="+device_id)
        .then((response) => {
            return response.data;
        });
};
const UserService = {
    getStats,
    getUserBoard,
    updateUserProfile,
    getCommonData,
    play,
    action,
    deleteBySelf,
    getPlayedGame,
    getPlayedHistory,
    getLogonHistory,
    doChangeEmail,
    doChangePassword,
    doUpdatePassword,
    doUpdateEmail,
    doUpdateFavorites,
    doUpdateSettings,
    doUpdateLocale,
    getUserInfoByToken,
    doCloseAnotherSessions,
    getConfigData
};

export default UserService;