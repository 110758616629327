import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import { withGameTableContext } from '../../../contexts/GameTableContext';
import styles from "./VolumeBar.module.css";
import VolumeMute from "../../../components/icons/Mute.svg";
import classNames from "classnames";
class VolumeBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            barStillDown: false,
            value: props.defaultValue * 100
        }
    }
    componentDidMount = () => {

    }
    handleFinish = (e) => {
        const {onChangeVolume} = this.props;
        this.setState({
            barStillDown: false
        }, () => {
            let volume = this.state.value;
            console.log("Volume:", (1 * volume) / 100)
            if(onChangeVolume)
                onChangeVolume((1 * volume) / 100)
            
        })
    }
    calculateFill = (e) => {
        let offsetX = e.offsetX
        if (e.type === "touchmove") {
          offsetX = e.touches[0].pageX - e.touches[0].target.offsetLeft
        }
        if(!offsetX){
            offsetX = e.nativeEvent.offsetX;
        }
        const width = e.target.offsetWidth - 30;
        let value = Math.round(Math.max(
            Math.min(
                // Отнимаем левый паддинг
                (offsetX - 15) / width * 100.0,
                100.0
            ),
            0
        ));
        this.setState({
            value
        })
      }
      
    handleChangeValue = (e) => {
        console.log("value", e.target.value);
    }
    handleStart = (e) => {
        this.setState({
            barStillDown: true
        }, () => {
            this.calculateFill(e);
        })
    }
    handleMove = (e) => {
        if(this.state.barStillDown)
            this.calculateFill(e);
        
    }
    handleWheel = (e) => {
        const newValue = + this.state.value + e.deltaY * 0.5;

        this.setState({
            value: Math.round((Math.max(
                Math.min(
                    newValue,
                    100.0
                ),
                0
            )))
        })
    }
    handleMute = (e) => {
        const {onChangeVolume} = this.props;
        this.setState({
            barStillDown: false,
            value: 0
        }, () => {
            let volume = this.state.value;
            console.log("Volume:", (1 * volume) / 100)
            if(onChangeVolume)
                onChangeVolume((1 * volume) / 100)

        })
    }
    render() {
        const {label} = this.props;
        return (
            <React.Fragment>
                <label className="volume-label">{label}</label>
                <div className="volume" onMouseUp={this.handleFinish} onTouchEnd={this.handleFinish}>
                    <div className={classNames(styles.VolumeMuteButton, this.state.value === 0?styles.VolumeMuteButtonSvg:"")} onClick={this.handleMute}>
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="18" height="18"
                             viewBox="0 0 75 75"  strokeWidth={"5"}>
                            <path d="m39,14-17,15H6V48H22l17,15z" strokeLinejoin={"round"}/>
                            <path d="m49,26 20,24m0-24-20,24" fill="none" strokeLinecap={"round"}/>
                        </svg>
                    </div>
                    <input type="range" min="0" max="100" value={this.state.value} className="volume-range" />
                    <div className="bar-hoverbox" 
                        onTouchStart={this.handleStart} 
                        onTouchMove={this.handleMove}
                        onMouseDown={this.handleStart}
                        onMouseMove={this.handleMove}
                        onWheel={this.handleWheel}
                    >
                        <div className="bar">
                            <div className="bar-fill" style={{width: this.state.value + "%"}}></div>
                        </div>
                    </div>
                </div> 
            </React.Fragment>
        );
    }
}

export default withApplicationContext(withGameTableContext(VolumeBar));