import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withGameTableContext} from "../../../contexts/GameTableContext"
import styles from "../GameTable.module.css";
import classNames from "classnames";
class Timer extends Component {
    refTimer = null;
    constructor(props) {
        super(props);
        this.state = {
            timer: props.gameTableContext.state.timer
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const {gameTableContext} = this.props;
        if(gameTableContext.state.timer !== this.state.timer && !gameTableContext.state.timer.endsWith("00")){
            if(gameTableContext.state.timer !== "Finish"){
                this.setState({
                    timer: gameTableContext.state.timer
                })
            }else{
                if(this.state.timer !== "0:00"){
                    this.setState({
                        timer: "0:00"
                    })
                }

            }

        }

    }

    render() {
        const {gameTableContext, customClass, nonZero} = this.props;
        return (
            <div className={classNames(styles.Timer, customClass)}>
                {nonZero?this.state.timer.replaceAll("0:", ""):this.state.timer}
            </div>
        );
    }
}

export default withApplicationContext(withGameTableContext((Timer)));